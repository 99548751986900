<template>
  <div class="app">
    <div class="logo">
      <div class="img">
        <img :src="require(`@/assets/img/svg/` + logo)" :alt="logo" />
      </div>
      <button class="btn-peach" @click="showMenu">Смотреть меню</button>
    </div>
    <div class="bg">
      <img :src="require(`@/assets/img/` + bg)" :alt="bg" />
    </div>
    <div class="mask"></div>
  </div>
</template>

<script>
export default {
  name: "t-main",
  data() {
    return {
      logo: `logo.svg`,
      bg: `app.png`,
    };
  },
  methods: {
    showMenu() {
      this.$router.push("/products");
    },
  },
  mounted() {
    if (window.innerWidth <= 767) {
      this.logo = `logo-vert.svg`;
      this.bg = `app-mobile.png`;
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.app {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .mask {
    background: #2a374b;
    mix-blend-mode: multiply;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .logo {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
    z-index: 1;
    .img {
      width: 362px;
      height: 192px;
      margin: 0 auto;
      @media (max-width: $ms3) {
        width: 205px;
        height: 272px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .btn-peach {
      width: 296px;
      height: 80px;
      font-weight: 700;
      border-radius: 10px;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.04em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 53px auto 0;
      padding: 2rem 0;
      text-transform: uppercase;
      @media (max-width: $ms3) {
        width: 269px;
        height: 60px;
        margin: 127px auto 0;
        padding: 1rem 0;
      }
    }
  }
  .bg {
    display: flex;
    justify-content: center;
    @media (max-width: $ms3) {
      height: 100vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
