<template>
  <footer class="footer">
    <div class="footer__images">
      <img :src="require(`@/assets/img/` + img + png)" :alt="img" />
      <img :src="require(`@/assets/img/` + img + png)" :alt="img" />
    </div>
  </footer>
</template>

<script>
export default {
  name: "t-ornament",
  data() {
    return {
      img: `ornament`,
      png: `.png`,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
.footer {
  &__images {
    display: flex;
    background-color: #fff;
    height: 105px;
    @media (max-width: $ms3) {
      height: 50px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
