<template>
  <t-header />
  <div class="product pt">
    <div class="product__container _container">
      <button class="btn btn-arrow" @click="$router.push('/products/')"><img src="@/assets/img/svg/arrow.svg" alt="arrow" />Меню</button>
      <div class="card-product">
        <div class="card-product__blocks">
          <div class="card-product__view">
            <div class="card-product__img">
              <a :href="product.image" data-fancybox="gallery">
                <img :src="product.image" :alt="product.real_name" />
              </a>
            </div>
            <div class="card-product__values">
              <div class="card-product__cols-vals">
                <div class="card-product__col-val">
                  <span>{{ product.energy }}</span>
                  <span>калории</span>
                </div>
                <div class="card-product__col-val">
                  <span>{{ product.proteins }}</span>
                  <span>белки</span>
                </div>
                <div class="card-product__col-val">
                  <span>{{ product.fat }}</span>
                  <span>жиры</span>
                </div>
                <div class="card-product__col-val">
                  <span>{{ product.carbohydrates }}</span>
                  <span>углеводы</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-product__body">
            <h2 class="card-product__subtitle">{{ product.category.real_name }}</h2>
            <div class="card-product__text">
              <h1 class="card-product__title">{{ product.real_name }}</h1>
              <p class="card-product__desc" v-html="product.description"></p>
            </div>
            <div class="card-product__values">
              <div class="card-product__cols-vals">
                <div class="card-product__col-val">
                  <span>{{ product.energy }}</span>
                  <span>калории</span>
                </div>
                <div class="card-product__col-val">
                  <span>{{ product.proteins }}</span>
                  <span>белки</span>
                </div>
                <div class="card-product__col-val">
                  <span>{{ product.fat }}</span>
                  <span>жиры</span>
                </div>
                <div class="card-product__col-val">
                  <span>{{ product.carbohydrates }}</span>
                  <span>углеводы</span>
                </div>
              </div>
            </div>
            <div class="card-product__change-order">
              <div class="card-product__weights">
                <div class="card-product__weight">{{ product.weight }} гр</div>
              </div>
              <div class="card-product__price">
                <span>Цена:</span> <span>{{ product.price }} ₽</span>
              </div>
            </div>
            <t-changed v-if="isIndigrients" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <t-slider :recommendedProducts="recommendedProducts" />
</template>

<script>
import { Navigation, Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-product",
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
    };
  },
  data() {
    return {
      isChangeOrder: true,
      isIndigrients: false,
      product: {},
      errors: [],
      recommendedProducts: [],
    };
  },
  methods: {
  },
  created() {
    let productId = this.$route.params.id;
    let self = this;

    axios.get(api + `orders/`).then(function (response) {
      self.orderObj = response.data;
    });

    axios
      .get(api + `products/${productId}`)
      .then((response) => {
        self.product = response.data;
        console.log(response);
      })
      .catch((e) => {
        this.errors.push(e);
      });
    axios
      .get(api + `recommended-products/?product_id=${productId}`)
      .then((response) => {
        self.recommendedProducts = response.data;
        console.log(response);
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/slider";

.product {
  padding-bottom: 20px;
  &__content {
    @include adaptive-value("margin-top", 56, 20, 0);
  }

  &__supplement-order {
    color: #000;
    @include adaptive-font(30, 18, 0);
    @include adaptive-value("line-height", 31, 20, 0);
  }

  .blocks {
    margin: 0 auto;
    padding-bottom: 114px;
    position: relative;

    .block {
      flex: 0 0 23%;
      margin: 20px auto 0;
      @media (max-width: $ms3) {
        flex: 0 0 35%;
      }
      @media (max-width: $ms4) {
        flex: 0 0 50%;
      }
    }
  }
}
</style>
