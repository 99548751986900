import { createRouter, createWebHistory } from "vue-router";
import Main from "./pages/Main";
import Products from "./pages/Products";
import Product from "./pages/Product";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Main,
    },
    {
      path: "/products",
      component: Products,
    },
    {
      path: "/product/:id",
      component: Product,
    },
  ],
});
