<template>
  <div class="add-cancel">
    <button class="btn btn-green">Добавить за <span>129 ₽</span></button>
    <button class="btn btn-red">Отменить</button>
  </div>
</template>

<script>
export default {
  name: "t-add-cancel",
  data() {
    return {};
  },
};
</script>
