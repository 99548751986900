<template>
  <footer class="footer bg-green">
    <div class="footer__container _container">
      <div class="footer__buttons">
        <button class="btn btn-red" @click="this.$router.push('/')">{{ cancel }}</button>
        <button class="btn btn-beige" @click="sendOrder()">{{ done }}</button>
      </div>
    </div>
  </footer>
</template>

<script>
// import axios from "axios";
// import {api} from "@/api/api";

export default {
  name: "t-footer-order",
  data() {
    return {
      cancel: `Отменить заказ`,
      done: `Оплатить`,
    };
  },
  methods: {
    sendOrder: function () {
      this.$router.push("/payment");
      // axios.post(api + 'orders/confirm/', {});
      // this.$router.push('/');
    }
  }
};
</script>
