<template>
  <div class="show-popup">
    <div class="show-popup__container _container">
      <div class="popup__body">
        <div class="blocks">
          <div class="block">
            <div class="block__img">
              <img src="@/assets/img/dishes/small/1.png" alt="1" />
            </div>
            <div class="block__body">
              <h4 class="block__title">Курай с курицей и томатами в сливочном соусе</h4>
              <div class="block-flex">
                <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" title="info" /></div>
                <div class="block__btn">
                  <button class="btn-remove">Удалить</button>
                </div>
              </div>
              <t-counter-price />
            </div>
          </div>
          <div class="block">
            <div class="block__img">
              <img src="@/assets/img/dishes/small/1.png" alt="1" />
            </div>
            <div class="block__body">
              <h4 class="block__title">Курай с курицей и томатами в сливочном соусе</h4>
              <div class="block-flex">
                <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" title="info" /></div>
                <div class="block__btn">
                  <button class="btn-remove">Удалить</button>
                </div>
              </div>
              <t-counter-price />
            </div>
          </div>
          <div class="block">
            <div class="block__img">
              <img src="@/assets/img/dishes/small/1.png" alt="1" />
            </div>
            <div class="block__body">
              <h4 class="block__title">Курай с курицей и томатами в сливочном соусе</h4>
              <div class="block-flex">
                <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" title="info" /></div>
                <div class="block__btn">
                  <button class="btn-remove">Удалить</button>
                </div>
              </div>
              <t-counter-price />
            </div>
          </div>
          <div class="block">
            <div class="block__img">
              <img src="@/assets/img/dishes/small/1.png" alt="1" />
            </div>
            <div class="block__body">
              <h4 class="block__title">Курай с курицей и томатами в сливочном соусе</h4>
              <div class="block-flex">
                <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" title="info" /></div>
                <div class="block__btn">
                  <button class="btn-remove">Удалить</button>
                </div>
              </div>
              <t-counter-price />
            </div>
          </div>
          <div class="block">
            <div class="block__img">
              <img src="@/assets/img/dishes/small/1.png" alt="1" />
            </div>
            <div class="block__body">
              <h4 class="block__title">Курай с курицей и томатами в сливочном соусе</h4>
              <div class="block-flex">
                <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" title="info" /></div>
                <div class="block__btn">
                  <button class="btn-remove">Удалить</button>
                </div>
              </div>
              <t-counter-price />
            </div>
          </div>
          <div class="block">
            <div class="block__img">
              <img src="@/assets/img/dishes/small/1.png" alt="1" />
            </div>
            <div class="block__body">
              <h4 class="block__title">Курай с курицей и томатами в сливочном соусе</h4>
              <div class="block-flex">
                <div class="block__info"><img src="@/assets/img/svg/info.svg" alt="info" title="info" /></div>
                <div class="block__btn">
                  <button class="btn-remove">Удалить</button>
                </div>
              </div>
              <t-counter-price />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-show-popup",
  created() {},
  data() {
    return {};
  },
};
</script>
