<template>
  <t-header />
  <div class="products pt">
    <div class="products__container _container">
      <div class="products__columns">
        <aside class="sidebar">
          <ul class="menu">
            <li v-for="category in categories" :product="product" :key="category.id">
              <a @click="changeCategory(category.id)" class="menu__item" :href="`#categoryid` + category.id" :class="selectedCategoryId === category.id ? `active` : false">{{ category.real_name }}</a>
            </li>
          </ul>
        </aside>
        <div class="products__body" v-for="category in categories" :key="category.id" :id="`categoryid` + category.id">
          <h3 class="products__title">{{ category.real_name }}</h3>
          <div class="blocks">
            <div class="block" v-for="product in products" :product="product" :key="product.id" v-show="product.category.id == category.id">
              <div class="block__img">
                <router-link :to="`/product/${product.id}`">
                  <img v-lazy="{ src: product.thumbnail, loading: 'https://media.tcode.online/logo/2023/2/1675336869722.png', error: 'https://media.tcode.online/logo/2023/2/1675336869722.png' }" :alt="product.real_name" />
                </router-link>
              </div>
              <div class="block__body">
                <div>
                  <router-link :to="`/product/${product.id}`" class="block__title">{{ product.real_name }}</router-link>
                  <div class="block__info">
                    {{ product.intro_text }}
                  </div>
                  <div class="block__weight-price">
                    <div class="block__weight">{{ product.weight }} гр</div>
                    <div class="block__price">{{ product.price }} ₽</div>
                  </div>
                </div>
                <div>
                  <router-link :to="`/product/${product.id}`" class="btn-dark btn-more">Подробнее</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <t-select-combo v-if="isShowCombo" />
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-products",
  data() {
    return {
      isShowCombo: false,
      categories: [],
      title: `Салаты`,
      selectedCategoryTitle: `Салаты`,
      selectedCategoryId: 0,
      products: [],
      info: `info.svg`,
    };
  },
  methods: {
    changeCategory: function (categoryId) {
      this.selectedCategoryId = categoryId;
    },
  },
  mounted() {
    let self = this;
    axios.get(api + `orders/`).then(function (response) {
      self.orderObj = response.data;
    });
    axios
      .get(api + `categories/`)
      .then((response) => {
        this.categories = response.data;
        this.selectedCategoryId = this.categories[0].id;
        axios
          .get(api + `products/`)
          .then((response) => {
            this.products = response.data;
          })
          .catch((e) => {
            this.errors.cafes = e.message;
          });
      })
      .catch((e) => {
        this.errors.cafes = e.message;
      });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";

.products {
  padding-bottom: 5rem;
  position: relative;

  &__columns {
    @media (max-width: $ms3) {
      padding-bottom: 2rem;
    }
  }

  &__body {
    margin-top: 39px;
    @media (max-width: $ms3) {
      margin-top: 9px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #d07f67;
    @media (max-width: $ms3) {
      font-size: 26px;
      line-height: 35px;
    }
  }

  .blocks {
    position: relative;
    margin: -1rem -13.5px 5rem;
    @media (max-width: $ms3) {
      margin: 0 auto;
      padding: 0;
    }

    &::-webkit-scrollbar-track {
      background: rgba(40, 115, 95, 0.2);
      border-radius: 10px;
      margin-top: 28px;
    }

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2a374b;
      border-radius: 10px;
    }

    .block {
      flex: 0 0 22%;
      margin: 28px 13.5px 0;
      @media (max-width: $ms2) {
        margin: 28px 5px 0;
      }
      @media (max-width: $ms3) {
        flex: 0 0 47%;
      }
      @media (max-width: 360px) {
        flex: 0 0 46%;
      }
    }
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $ms3) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 1rem;
    height: auto;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 15px;
  }

  li {
    margin-top: 15px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    cursor: pointer;
    @media (max-width: $ms3) {
      margin-top: 0;
      margin-right: 7px;
    }
  }

  &__item {
    border: 1px solid #2a374b;
    border-radius: 10px;
    font-size: 30px;
    line-height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2a374b;
    width: fit-content;
    height: 80px;
    padding: 0 2rem;
    @media (max-width: $ms3) {
      height: 50px;
      font-size: 16px;
      line-height: 20px;
    }

    &:hover,
    &:focus {
      background: #2a374b;
      color: #fff;
      transition: 0.2s ease 0.2s;
    }
  }

  .active {
    background: #2a374b;
    color: #fff;
  }
}
</style>
