<template>
  <div class="slider">
    <div class="slider__container _container">
      <h2 class="slider__title">Дополнительные предложения</h2>
      <swiper class="blocks" :modules="modules" :slides-per-view="4" :space-between="28" navigation :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide class="block" v-for="rd in recommendedProducts" :key="rd.id">
          <div class="block__img">
            <router-link :to="`/product/${rd.id}`" @click="reloadPage">
              <img :src="rd.thumbnail" alt="1" />
            </router-link>
          </div>
          <div class="block__body">
            <router-link :to="`/product/${rd.id}`" class="block__title" @click="reloadPage">{{ rd.real_name }}</router-link>
            <div class="block__info">{{ rd.intro_text }}</div>
            <router-link :to="`/product/${rd.id}`" class="btn-dark btn-more" @click="reloadPage">Подробнее</router-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "t-slider",
  props: ["recommendedProducts"],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
    };
  },
  methods: {
    reloadPage() {
      setTimeout(function () {
        window.location.reload();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/slider";
</style>
