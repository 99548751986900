<template>
  <div class="counter">
    <button class="counter__minus" @click="decrease"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
    <span class="counter__price">{{ 159 }} ₽</span>
    <button class="counter__plus" @click="increase"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
  </div>
</template>

<script>
export default {
  name: "t-counter",
  created() {},
  data() {
    return {
      counter: 1,
      price: 129,
    };
  },
  methods: {
    increase() {
      this.$store.commit("increase", this.product);
      this.$emit("increase", this.product);
    },
    decrease() {
      if (this.counts < 2) {
        this.isAddToCart = true;
        this.isCounts = false;
      }
      this.$store.commit("decrease", this.product);
      this.$emit("decrease", this.product);
    },
  },
  computed: {
    counts() {
      return this.$store.getters.productQuantity(this.product);
    },
  },
};
</script>

<style lang="scss" scoped></style>
