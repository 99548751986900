import { createApp } from "vue";
import VueLazyLoad from 'vue3-lazyload'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Top from "./components/Top";
import Ornament from "./components/Ornament";
import Header from "./components/Header";
import OrdersHall from "./components/OrdersHall";
import OrderHall from "./components/OrderHall";
import TotalSum from "./components/TotalSum";
import Counter from "./components/Counter";
import Counter2 from "./components/Counter2";
import Counter3 from "./components/Counter3";
import CounterPrice from "./components/CounterPrice";
import Changed from "./components/Changed";
import SelectCombo from "./components/SelectCombo";
import ShowPopup from "./components/ShowPopup";
import BlockOrder from "./components/BlockOrder";
import Sidebar from "./components/Sidebar";
import MenuItems from "./components/MenuItems";
import BtnArrow from "./components/BtnArrow";
import AddCancel from "./components/AddCancel";
import Keyboard from "./components/Keyboard";
import FooterOrder from "./components/FooterOrder";
import Slider from "./components/Slider";
import CartItem from "./components/CartItem";
import AddToCart from "./components/AddToCart";

const app = createApp(App);
app.component("t-top", Top);
app.component("t-ornament", Ornament);
app.component("t-header", Header);
app.component("t-orders-hall", OrdersHall);
app.component("t-order-hall", OrderHall);
app.component("t-total-sum", TotalSum);
app.component("t-counter", Counter);
app.component("t-counter2", Counter2);
app.component("t-counter3", Counter3);
app.component("t-counter-price", CounterPrice);
app.component("t-changed", Changed);
app.component("t-select-combo", SelectCombo);
app.component("t-show-popup", ShowPopup);
app.component("t-block-order", BlockOrder);
app.component("t-sidebar", Sidebar);
app.component("t-menu-items", MenuItems);
app.component("t-btn-arrow", BtnArrow);
app.component("t-add-cancel", AddCancel);
app.component("t-keyboard", Keyboard);
app.component("t-footer-order", FooterOrder);
app.component("t-slider", Slider);
app.component("t-cart-item", CartItem);
app.component("t-add-to-cart", AddToCart);
app.use(router);
app.use(store);
app.use(VueLazyLoad);
app.mount("#app");
