<template>
  <div class="block-indigrients">
    <div class="block-indigrient" v-for="indigrient in indigrients" :key="indigrient.id">
      <div class="indigrient">{{ indigrient.name }}</div>
      <div class="block-flex">
        <div class="price">{{ indigrient.price }} ₽</div>
        <div class="counter">
          <button class="counter__minus" @click="delCounter"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
          <span class="counter__counts">{{ indigrient.counter }} шт</span>
          <button class="counter__plus" @click="addCounter"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
        </div>
      </div>
    </div>
  </div>
  <t-add-cancel />
</template>

<script>
export default {
  name: "t-changed",
  created() {},
  data() {
    return {
      indigrients: [
        { id: 1, name: `Курица`, price: 50, counter: 1 },
        { id: 2, name: `Томаты`, price: 50, counter: 1 },
        { id: 3, name: `Соусы`, price: 50, counter: 1 },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.block-indigrients {
  margin-top: 86px;
  @media (max-width: $ms3) {
    margin-top: 50px;
  }
}
.block-indigrient {
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  .indigrient {
    font-family: "Fregat";
    font-style: normal;
    font-weight: 400;
    @include adaptive-font(30, 14, 0);
    @include adaptive-value("line-height", 31, 20, 0);
    color: #000;
    padding: 0px 0px 0px 14px;
  }
}
.block-counter,
.block-indigrient {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .counter {
    width: 180px;
    background: none;
    box-shadow: none;
    &__minus,
    &__plus {
      width: 50px;
      height: 50px;
    }
    &__counts {
      font-weight: 700;
      @include adaptive-font(20, 14, 0);
      @include adaptive-value("line-height", 21, 15, 0);
    }
  }
  .price {
    margin-right: 12px;
    font-weight: 700;
    @include adaptive-font(25, 14, 0);
    @include adaptive-value("line-height", 26, 15, 0);
    flex: 0 0 auto;
  }
  .counter,
  .price {
    color: #28735f;
  }
}
</style>
