<template>
  <aside class="sidebar">
    <t-btn-arrow />
    <t-menu-items />
  </aside>
</template>

<script>
export default {
  name: "t-sidebar",
};
</script>
