<template>
  <div class="blocks">
    <div class="block">
      <div class="block__img">
        <router-link :to="`/product/${product.id}`">
          <img :src="product.image" :alt="product.real_name" />
        </router-link>
      </div>
      <div class="block__body">
        <router-link :to="`/product/${product.id}`" class="block__title">{{ product.real_name }}</router-link>
        <div class="block__info">
          {{ product.intro_text }}
        </div>
        <t-add-to-cart :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-cart-item",
  data: () => ({
    rub: "₽",
  }),
  props: ["product"],
  methods: {
    increase() {
      this.$store.commit("increase", this.product);
    },
    decrease() {
      if (this.counts < 2) {
        this.isAddToCart = false;
        this.isCount = true;
      }
      this.$store.commit("decrease", this.product);
    },
  },
  computed: {
    counts() {
      return this.$store.getters.productQuantity(this.product);
    },
    sum() {
      return this.product.price * this.product.quantity;
    },
  },
};
</script>

<style lang="scss" scoped></style>
