<template>
  <div class="counter">
    <button class="counter__minus" @click="delCounter"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
    <span class="counter__counts">{{ counter }} шт.</span>
    <span class="counter__line"></span>
    <span class="counter__price">{{ counter * price }} ₽</span>
    <button class="counter__plus" @click="addCounter"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
  </div>
</template>

<script>
export default {
  name: "t-counter-price",
  data() {
    return {
      counter: 1,
      price: 129,
    };
  },
  methods: {
    addCounter() {
      if (this.counter >= 999) return false;
      this.counter++;
    },
    delCounter() {
      if (this.counter <= 1) return false;
      this.counter--;
    },
  },
};
</script>
