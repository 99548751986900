<template>
  <div class="total-sum">
    <div class="total-sum__container _container">
      <div class="total-sum__discount" v-if="isDiscount">Скидка <span>100</span> ₽</div>
      <div class="total-sum__price">Итого <span>{{ orderObj.full_price }}</span> ₽</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-total-sum",
  props: ["orderObj"],
  data() {
    return {
      isDiscount: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
