<template>
  <div class="counter">
    <button class="counter__minus" @click="delCounter"><img src="@/assets/img/svg/minus.svg" alt="minus" /></button>
    <span class="counter__counts">{{ counter }} шт</span>
    <button class="counter__plus" @click="addCounter"><img src="@/assets/img/svg/plus.svg" alt="plus" /></button>
  </div>
</template>

<script>
export default {
  name: "t-counter2",
  created() {},
  data() {
    return {
      counter: 1,
    };
  },
  props: {},
  methods: {
    addCounter() {
      if (this.counter >= 99) return false;
      this.counter++;
    },
    delCounter() {
      if (this.counter <= 1) return false;
      this.counter--;
    },
  },
};
</script>

<style lang="scss" scoped></style>
