<template>
  <div class="keyboard">
    <div class="keyboard__block">
      <div class="keyboard__container _container">
        <div class="keyboard__body">
          <input type="text" v-model="message" placeholder="Введите промокод" class="keyboard__title">
          <div class="keyboard__keys" @click="typeLetter">
            <button class="keyboard__key key">1</button>
            <button class="keyboard__key key">2</button>
            <button class="keyboard__key key">3</button>
            <button class="keyboard__key key">4</button>
            <button class="keyboard__key key">5</button>
            <button class="keyboard__key key">6</button>
            <button class="keyboard__key key">7</button>
            <button class="keyboard__key key">8</button>
            <button class="keyboard__key key">9</button>
            <button class="keyboard__key key">0</button>
            <button class="btn btn-green key"><img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" /></button>
            <button class="btn btn-green btn-long key">Очистить</button>
            <button class="keyboard__key key">й</button>
            <button class="keyboard__key key">ц</button>
            <button class="keyboard__key key">у</button>
            <button class="keyboard__key key">к</button>
            <button class="keyboard__key key">е</button>
            <button class="keyboard__key key">н</button>
            <button class="keyboard__key key">г</button>
            <button class="keyboard__key key">ш</button>
            <button class="keyboard__key key">щ</button>
            <button class="keyboard__key key">з</button>
            <button class="keyboard__key key">х</button>
            <button class="keyboard__key key">ъ</button>
            <button class="keyboard__key key">ё</button>
            <button class="keyboard__key key">ф</button>
            <button class="keyboard__key key">ы</button>
            <button class="keyboard__key key">в</button>
            <button class="keyboard__key key">а</button>
            <button class="keyboard__key key">п</button>
            <button class="keyboard__key key">р</button>
            <button class="keyboard__key key">о</button>
            <button class="keyboard__key key">л</button>
            <button class="keyboard__key key">д</button>
            <button class="keyboard__key key">ж</button>
            <button class="keyboard__key key">э</button>
            <button class="btn btn-green btn-long key">TAB</button>
            <button class="keyboard__key key">я</button>
            <button class="keyboard__key key">ч</button>
            <button class="keyboard__key key">с</button>
            <button class="keyboard__key key">м</button>
            <button class="keyboard__key key">и</button>
            <button class="keyboard__key key">т</button>
            <button class="keyboard__key key">ь</button>
            <button class="keyboard__key key">б</button>
            <button class="keyboard__key key">ю</button>
            <button class="keyboard__key key">_</button>
            <button class="btn btn-green key"><img src="@/assets/img/svg/space.svg" alt="space" class="space" /></button>
            <button class="btn btn-green btn-long key">ABC</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <div class="footer__container _container">
      <div class="footer__buttons">
        <button class="btn btn-red">{{ cancel }}</button>
        <button class="btn btn-green">{{ done }}</button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "t-keyboard",
  created() {},
  data() {
    return {
      cancel: `Отмена`,
      done: `Применить промокод`,
      message: ``,
    };
  },
  methods: {
    typeLetter() {
      if (event.target.className !== "keyboard__keys") {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear") this.message = this.message.substr(0, this.message.length - 1);
        else if (key === "Очистить") this.message = "";
        else if (key === "TAB") this.message = this.message.toUpperCase();
        else if (key === "space") this.message += " ";
        else this.message += key;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #fffff7;
}
</style>
