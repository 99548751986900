<template>
  <button class="btn btn-arrow"><img src="@/assets/img/svg/arrow.svg" alt="arrow" />Назад</button>
</template>

<script>
export default {
  name: "t-btn-arrow",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
