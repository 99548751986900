<template>
  <div class="block-order">
    <div class="block-order__top">
      <div class="block-order__title">
        <p>{{ order.name }}</p>
      </div>
      <div :class="order.status === null ? `block-order__price` : `block-order__status`">{{ order.status === null ? price * counter + " ₽" : order.status }}</div>
    </div>
    <div class="block-order__middle">
      <p>— {{ order.desc }}</p>
    </div>
    <div class="block-order__bottom">
      <button class="btn btn-remove">Удалить</button>
      <t-counter2 />
      <button class="btn btn-change">Изменить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-block-order",
  created() {},
  data() {
    return {
      counter: 1,
      price: 129,
    };
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addCounter() {
      if (this.counter >= 99) return false;
      this.counter++;
    },
    delCounter() {
      if (this.counter <= 1) return false;
      this.counter--;
    },
  },
};
</script>

<style lang="scss" scoped></style>
