<template>
  <header class="header">
    <div class="header__container _container">
      <div class="logo">
        <router-link :to="`/`"> <img :src="require(`@/assets/img/svg/` + logo)" :alt="logo" /> </router-link>
      </div>
      <div class="name">Кафе-музей татарской кухни</div>
    </div>
  </header>
</template>

<script>
export default {
  name: "t-header",
  data() {
    return {
      bg: `app.png`,
      logo: `logo-header.svg`,
    };
  },
};
</script>
